import gql from 'graphql-tag'


const response = `
  printer
`

export const DETAIL_STATUS = (templateType) => gql`query DETAIL_STATUS ($deviceType: String!, $deviceId: Int!) {
  status: detail${templateType}Status (deviceType: $deviceType, deviceId: $deviceId) {${response}}
}`

export const CONFIG_DEVICE = (templateType) => gql`mutation CONFIG_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}ConfigInput!) {
  config: config${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${response}}
}`


export const PRINT_DEVICE = (templateType) => gql`mutation PRINT_DEVICE ($deviceType: String!, $deviceId: Int!, $url: String!) {
  result: print${templateType} (deviceType: $deviceType, deviceId: $deviceId, url: $url)
}`
